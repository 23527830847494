export default function SpotifyIcon({ size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 168 168"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_708_4)">
        <path
          d="M83.9969 0.2771C37.7479 0.2771 0.253906 37.7701 0.253906 84.0191C0.253906 130.27 37.7479 167.76 83.9969 167.76C130.251 167.76 167.741 130.27 167.741 84.0191C167.741 37.7731 130.251 0.2811 83.9959 0.2811L83.9969 0.2771ZM122.401 121.057C120.901 123.517 117.681 124.297 115.221 122.787C95.5589 110.777 70.8069 108.057 41.6569 114.717C38.8479 115.357 36.0479 113.597 35.4079 110.787C34.7649 107.977 36.5179 105.177 39.3339 104.537C71.2339 97.2461 98.5969 100.387 120.671 113.877C123.131 115.387 123.911 118.597 122.401 121.057ZM132.651 98.2521C130.761 101.327 126.741 102.297 123.671 100.407C101.161 86.5681 66.8479 82.5611 40.2229 90.6431C36.7699 91.6861 33.1229 89.7401 32.0749 86.2931C31.0349 82.8401 32.9819 79.2001 36.4289 78.1501C66.8419 68.9221 104.651 73.3921 130.501 89.2771C133.571 91.1671 134.541 95.1861 132.651 98.2521ZM133.531 74.5081C106.541 58.4771 62.0109 57.0031 36.2419 64.8241C32.1039 66.0791 27.7279 63.7431 26.4739 59.6051C25.2199 55.4651 27.5539 51.0921 31.6949 49.8341C61.2759 40.8541 110.451 42.5891 141.525 61.0361C145.255 63.2451 146.475 68.0521 144.265 71.7691C142.065 75.4911 137.245 76.7181 133.535 74.5081H133.531Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_708_4">
          <rect width="168" height="168" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
