import React from "react";
import { Box, Fab, IconButton, Typography } from "@mui/material";
import { Add, LiveTv } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE, currentUser } from "../App";

export default function FloatingButtons() {
  const navigate = useNavigate();

  if (currentUser.role !== "artist" || window.location.pathname === "/post") {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 150,
        right: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: 4,
        zIndex: 1000,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {/* <Typography variant="caption" sx={{ color: "text.secondary" }}>
          Post
        </Typography> */}
        <Fab
          color="primary"
          aria-label="add post"
          onClick={() => navigate("/post")}
        >
          <Add />
        </Fab>
      </Box>
    </Box>
  );
}
