import axios from "axios";
import { useEffect, useState } from "react";

import { Avatar, Box, IconButton, Typography } from "@mui/material";

import { Star, StarBorder, Verified } from "@mui/icons-material";

import UserDrawer from "@/components/UserDrawer";
import CommentActions from "@/components/CommentActions";
import { API_BASE, currentUser } from "@/App";
import { formatSeconds } from "@/utils";
import { fromNow } from "@/utils/time";

export default function CommentCard({ event, fetchComments, content }) {
  const avatarSize = event.parent_id ? 28 : 34;
  const [favorite, setFavorite] = useState(false); // lets set in useEffect is event changes
  const [favoriteCount, setFavoriteCount] = useState(0); // lets set in useEffect is event changes

  const [showUser, setShowUser] = useState(false);

  const handleFavorite = async () => {
    setFavorite(!favorite);
    setFavoriteCount(favorite ? favoriteCount - 1 : favoriteCount + 1);
    const endpoint = API_BASE + "/event";
    const response = await axios.post(endpoint, {
      user_key: currentUser.key,
      content_key: event.content_key,
      parent_id: event.id,
      type: favorite ? "unfavorite" : "favorite",
    });
  };

  useEffect(() => {
    setFavorite(event.user_favorite);
    setFavoriteCount(event.favorite_count);
  }, [event]);

  return (
    <Box id={event.id} style={{ display: "flex", alignItems: "flex-start" }}>
      <>
        {/* these are logically related. perhaps the user drawer should own the avatar */}
        <Avatar
          src={event.user.meta.profile}
          sx={{ width: avatarSize, height: avatarSize, mr: 1 }}
          onClick={() => setShowUser(true)}
        />
        <UserDrawer
          userKey={event.user.key}
          open={showUser}
          setOpen={setShowUser}
        />
      </>

      <Box id="post-content-container" style={{ width: "100%" }}>
        <Box
          id="post-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginTop: "-5px",
          }}
        >
          <Box
            id="user-detail"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <Typography
              variant="body"
              style={{ marginRight: "3px", fontWeight: "bold" }}
            >
              {event.user.name}
            </Typography>

            {event.user.role == "artist" && (
              <Verified
                color={event.user.verified ? "secondary" : "disabled"}
                style={{ fontSize: "15px", marginRight: "5px" }}
              />
            )}

            {event.user.role == "user" && event.user.verified && (
              <Verified
                color="primary"
                style={{ fontSize: "15px", marginRight: "5px" }}
              />
            )}

            <Typography
              variant="caption"
              style={{ margin: "3px 3px 0 0", color: "lightgray" }}
            >
              {fromNow(event.created)}
            </Typography>

            {event.data.current_time && (
              <Typography
                variant="caption"
                style={{ margin: "3px 3px 0 3px", color: "lightgray" }}
              >
                (at {formatSeconds(event.data.current_time)})
              </Typography>
            )}

            {event.artist_favorite && (
              <Box>
                <Typography
                  variant="caption"
                  style={{
                    color: "lightgray",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  •
                  <Star
                    color="secondary"
                    style={{ fontSize: "15px", margin: "0 2px" }}
                  />
                  by artist
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box id="post-content" style={{ marginTop: "-5px" }}>
          {/* needs to wrap long comment */}
          <Box style={{ wordWrap: "break-all", whiteSpace: "pre-wrap" }}>
            <Typography variant="body" style={{ marginRight: "5px" }}>
              {event.data.text}
            </Typography>
          </Box>
        </Box>
        <CommentActions
          event={event}
          fetchComments={fetchComments}
          content={content}
        />
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginLeft: "10px",
        }}
      >
        <IconButton size="small" onClick={handleFavorite} sx={{ p: 0 }}>
          {favorite ? (
            <Star color="secondary" />
          ) : (
            <StarBorder style={{ color: "lightgray" }} />
          )}
        </IconButton>
        <Typography
          variant="caption"
          style={{ color: "lightgray", marginTop: "2px" }}
        >
          {favoriteCount > 0 ? favoriteCount : ""}
        </Typography>
      </Box>
    </Box>
  );
}
