import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {
  Avatar,
  Box,
  Drawer,
  Typography,
  IconButton,
  Button,
  Card,
  Container,
} from "@mui/material";
import {
  Verified,
  ReportOutlined,
  Cancel,
  Block,
  AutoStories,
  Instagram,
} from "@mui/icons-material";

import { API_BASE, currentUser } from "../App";
import SpotifyIcon from "@/fragments/SpotifyIcon";
import { fromNow } from "@/utils/time";

const outlinedStyle = {
  border: 1,
  borderColor: "#04FFFF",
  borderStyle: "solid",
};

export default function UserDrawer({ userKey, open, setOpen }) {
  const [user, setUser] = useState(null);
  const [comments, setComments] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const [loading, setLoading] = useState(false);

  const blockUser = async () => {
    const isConfirmed = confirm("Are you sure you want to block this user?");
    if (isConfirmed) {
      setLoading(true);
      try {
        const endpoint = API_BASE + "/user/block";
        const response = await axios.post(endpoint, {
          key: userKey,
          user_key: currentUser.key,
        });
        setUser(response.data);
        setBlocked(response.data.public === false);
      } catch (error) {
        console.error("Error blocking user:", error);
        // Handle error if needed, e.g., show an error message to the user
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const endpoint = API_BASE + "/user";
      const response = await axios.get(endpoint, {
        params: {
          key: userKey,
        },
      });
      setUser(response.data);
      setBlocked(response.data.public === false);
    };
    const fetchUserComments = async () => {
      const endpoint = API_BASE + "/user_comments";
      const response = await axios.get(endpoint, {
        params: {
          key: userKey,
        },
      });
      setComments(response.data.comments);
    };
    if (userKey && open) {
      fetchUser();
      fetchUserComments();
    }
  }, [userKey, open]);

  if (!user) {
    return null;
  }

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(!open)}
      PaperProps={{ style: { borderRadius: "20px 20px 0 0" } }}
      elevation={5}
    >
      <Container maxWidth="sm">
        <Box sx={{ m: 2, display: "flex", justifyContent: "space-between" }}>
          <IconButton onClick={() => setOpen(!open)}>
            <Cancel />
          </IconButton>
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50px",
            // background: "#121212",
            // height: "80vh",
          }}
          sx={{ pb: 8, pt: 2, gap: 4 }}
        >
          <Avatar
            src={user?.meta.profile}
            sx={{ width: 150, height: 150, opacity: blocked ? 0.3 : 1 }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              minHeight: "32px",
              opacity: blocked ? 0.3 : 1,
            }}
          >
            <Typography variant="h6">{user?.name}</Typography>

            <UserBadge user={user} />
            {user.meta.instagram && (
              <IconButton onClick={() => window.open(user.meta.instagram)}>
                <Instagram />
              </IconButton>
            )}
            {user.meta.spotify && (
              <IconButton onClick={() => window.open(user.meta.spotify)}>
                <SpotifyIcon size={20} />
              </IconButton>
            )}
          </Box>

          <Box
            sx={{ px: 4 }}
            style={{
              display: "flex",
              flexDirection: "column",

              justifyContent: "center",
            }}
          >
            {user?.meta.bio?.split("\n").map((line, index) => (
              <Typography key={index} variant="body1" sx={{ my: 0.4 }}>
                {line}
              </Typography>
            ))}
          </Box>

          {user?.role === "artist" && (
            <Button
              sx={{ borderRadius: "50px", width: "200px" }}
              variant="outlined"
              color="secondary"
              startIcon={<AutoStories />}
              onClick={() => {
                window.location.href = "/" + user.key;
              }}
            >
              Open Notebook
            </Button>
          )}

          {currentUser.role === "artist" && (
            <Box
              sx={{
                px: 4,
                overflowY: "scroll",
                "::WebkitScrollbar": {
                  display: "none",
                },
              }}
            >
              <Typography variant="h6">
                Commments
                {comments.length > 0 && ` (${comments.length})`}
              </Typography>
              {comments?.map((event, index) => (
                <Card
                  key={index}
                  sx={{ my: 3, p: 2, borderRadius: "20px", width: "100%" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ color: "lightgray", mb: 1, fontSize: 12 }}
                  >
                    {fromNow(event.created)}
                  </Typography>
                  {event.data.text}
                </Card>
              ))}
            </Box>
          )}

          {currentUser.role === "artist" && user?.role !== "artist" && (
            <Button
              sx={{ borderRadius: "50px", width: "200px" }}
              variant="text"
              color="error"
              startIcon={<Block />}
              onClick={blockUser}
              disabled={blocked || loading}
            >
              {blocked ? "User Blocked" : "Block User"}
            </Button>
          )}
        </Box>
      </Container>
    </Drawer>
  );
}

function UserBadge({ user, style }) {
  return (
    <>
      {user?.role === "artist" && (
        <Verified
          color={user.verified ? "secondary" : "disabled"}
          style={style}
        />
      )}

      {user?.role === "user" && user?.verified && (
        <Verified color="primary" style={style} />
      )}
    </>
  );
}
