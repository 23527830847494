import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LogoutIcon from "@mui/icons-material/Logout";
import VerifiedIcon from "@mui/icons-material/Verified";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";

import ReleaseDate from "../fragments/ReleaseDate";

import AppLogo from "../assets/app-logo.svg";

import { AccountCircle, Close, Verified } from "@mui/icons-material";
import { currentUser } from "../App";
import { handleLogout, setArtistKey } from "../utils";
import { Container } from "@mui/material";

const outlinedStyle = {
  border: 1,
  borderColor: "#04FFFF",
  borderStyle: "solid",
};

export default function CurrentUserDrawer() {
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        onClick={() => setDrawerOpen(!drawerOpen)}
        sx={{ width: "44px" }}
      >
        {currentUser.role === "artist" && (
          <Verified
            color={currentUser.verified ? "secondary" : "disabled"}
            style={{ fontSize: "15px", marginRight: "5px" }}
          />
        )}

        {/* {currentUser.name === "Parker Holcomb" && (
          <Verified
            color="primary"
            style={{ fontSize: "15px", marginRight: "5px" }}
          />
        )} */}

        <Avatar
          src={currentUser.meta?.profile}
          sx={{ width: 24, height: 24 }}
          style={currentUser.role == "artist" ? outlinedStyle : {}}
        />
      </Box>

      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => setDrawerOpen(!drawerOpen)}
        style={{ zIndex: 1000 }}
        PaperProps={{ style: { borderRadius: "20px 20px 0 0" } }}
        elevation={10}
      >
        <Container maxWidth="sm">
          <Box
            role="presentation"
            onClick={() => setDrawerOpen(!drawerOpen)}
            onKeyDown={() => setDrawerOpen(!drawerOpen)}
            // style={{ background: "#121212" }}
            sx={{ pb: 5, pl: 0, pr: 1 }}
          >
            <Box
              sx={{
                my: 4,
                ml: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                <Close />
              </IconButton>
              <img
                src={AppLogo}
                alt="notebook-logo"
                style={{ width: 160, opacity: 0.95 }}
                sx={{ mb: 2 }}
              />
              <Box sx={{ width: 40 }} />
            </Box>

            <List sx={{ my: 2 }}>
              <ListItem
                button
                key="profile"
                onClick={() => navigate("/profile")}
              >
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>

                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  sx={{ my: 0.5 }}
                >
                  <Typography>My Profile</Typography>

                  {currentUser["role"] === "artist" ? (
                    <Box>
                      <VerifiedIcon color="secondary" />
                    </Box>
                  ) : (
                    <Box />
                  )}
                </Box>
              </ListItem>
              {currentUser["role"] === "artist" && (
                <>
                  <ListItem button key="data" onClick={() => navigate("/data")}>
                    <ListItemIcon>
                      <AutoGraphIcon />
                    </ListItemIcon>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                      sx={{ my: 0.5 }}
                    >
                      <Typography>Artist Data</Typography>
                      <VerifiedIcon color="secondary" />
                    </Box>
                  </ListItem>
                </>
              )}

              <ListItem
                button
                key="subscriptions"
                onClick={() => navigate("/subscriptions")}
              >
                <ListItemIcon>
                  <AutoStoriesIcon />
                </ListItemIcon>
                <ListItemText primary="Artist Subscriptions" />
              </ListItem>

              {true && (
                <ListItem
                  button
                  key="accounts"
                  onClick={() => navigate("/accounts")}
                >
                  <ListItemIcon>
                    <SwitchAccountIcon />
                  </ListItemIcon>
                  <ListItemText primary="Accounts" />
                </ListItem>
              )}

              <ListItem
                button
                key="support"
                onClick={() => navigate("/contact")}
              >
                <ListItemIcon>
                  <ContactSupportIcon />
                </ListItemIcon>
                <ListItemText primary="Contact" />
              </ListItem>

              <ListItem button key="Logout" onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <Box sx={{ my: 2 }}>
                <img
                  src={AppLogo}
                  style={{
                    width: "250px",
                    display: "block",
                    margin: "auto",
                    opacity: 0.5,
                  }}
                />
              </Box> */}
              <ReleaseDate />
            </Box>
          </Box>
        </Container>
      </Drawer>
    </Box>
  );
}
