import axios from "axios";
import { useState, useRef, useMemo } from "react";
import {
  AppBar,
  Box,
  Card,
  IconButton,
  Toolbar,
  Typography,
  LinearProgress,
} from "@mui/material";
import { ArrowUpward, Close, Videocam } from "@mui/icons-material";
import { API_BASE, authHeaders, currentUser } from "../../App";
import ContentTextField from "./ContentTextField";
import ReactPlayer from "react-player";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";

// Create FFmpeg instance outside component to avoid recreation
const ffmpeg = new FFmpeg();

export default function NewVideo({ file }) {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [cover, setCover] = useState(null);
  const [progress, setProgress] = useState("");

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleUpload = async () => {
    setLoading(true);
    setProgress("Loading FFmpeg...");

    try {
      // Ensure FFmpeg is loaded
      if (!ffmpeg.loaded) {
        const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
        ffmpeg.on("log", ({ message }) => {
          setProgress(message);
        });

        await ffmpeg.load({
          coreURL: await toBlobURL(
            `${baseURL}/ffmpeg-core.js`,
            "text/javascript"
          ),
          wasmURL: await toBlobURL(
            `${baseURL}/ffmpeg-core.wasm`,
            "application/wasm"
          ),
        });
      }

      setProgress("Processing video...");

      // Write input file to FFmpeg's virtual FS
      await ffmpeg.writeFile("input.mov", await fetchFile(file));

      // Run FFmpeg command to strip metadata
      // -map_metadata -1 removes all metadata
      // -movflags use_metadata_tags ensures compatibility
      // -c:v copy -c:a copy preserves video and audio quality
      await ffmpeg.exec([
        "-i",
        "input.mov",
        "-map_metadata",
        "-1",
        "-movflags",
        "use_metadata_tags",
        "-c:v",
        "copy",
        "-c:a",
        "copy",
        "output.mov",
      ]);

      // Read the processed output file
      const data = await ffmpeg.readFile("output.mov");

      // Create a cleaned file
      const cleanedBlob = new Blob([data.buffer], { type: "video/quicktime" });
      const cleanedFile = new File([cleanedBlob], file.name, {
        type: "video/quicktime",
        lastModified: Date.now(),
      });

      // Clean up FFmpeg memory
      await ffmpeg.deleteFile("input.mov");
      await ffmpeg.deleteFile("output.mov");

      setProgress("Uploading processed video...");

      // Upload the cleaned file
      const endpoint = API_BASE + "/signed-upload";
      const response = await axios.get(endpoint, authHeaders);
      const uploadURL = response.data.upload_url;
      const publicURL = response.data.public_url;
      const key = response.data.content_key;

      const uploadResponse = await fetch(uploadURL, {
        method: "PUT",
        headers: { "Content-Type": cleanedFile.type },
        body: cleanedFile,
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed: ${uploadResponse.statusText}`);
      }

      setProgress("");
      setLoading(false);
      return { publicURL, key };
    } catch (error) {
      console.error("Error in handleUpload:", error);
      setProgress("Error: " + error.message);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      const { publicURL, key } = await handleUpload();

      const formData = new FormData();
      formData.append("key", key);
      formData.append("owner_key", currentUser.key);
      formData.append("artist_key", currentUser.key);
      formData.append("type", "video");
      formData.append("cover", cover);
      formData.append(
        "meta",
        JSON.stringify({
          text: text,
          url: publicURL,
        })
      );

      const endpoint = API_BASE + "/v1/post";
      const response = await axios.post(endpoint, formData, authHeaders);
      window.location.href = "/" + currentUser.key;
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const createThumbnail = async () => {
    console.log("Creating Thumbnail...");
    await new Promise((r) => setTimeout(r, 1000));

    const video = videoRef.current.getInternalPlayer();
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const coverBlob = await new Promise((resolve) => canvas.toBlob(resolve));
    setCover(coverBlob);
    console.log("Thumbnail Created", coverBlob);
  };

  const fileURL = useMemo(() => file && URL.createObjectURL(file), [file]);

  return (
    <Box>
      <PageAppBar
        handleSubmit={handleSubmit}
        loading={loading}
        progress={progress}
      />
      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
          mt: 2,
        }}
      >
        <ContentTextField text={text} setText={setText} />
        <Card style={{ borderRadius: "20px" }}>
          <ReactPlayer
            ref={videoRef}
            url={fileURL}
            controls
            width="100%"
            height="auto"
            onReady={createThumbnail}
          />
        </Card>
      </Box>
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </Box>
  );
}

function PageAppBar({ handleSubmit, loading, progress }) {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => window.location.reload()}>
          <Close style={{ fontSize: "2.0rem", color: "darkgray" }} />
        </IconButton>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Videocam sx={{ mr: 1 }} color="secondary" />
          <Typography variant="h6" style={{ color: "white" }}>
            {progress || "New Video"}
          </Typography>
        </Box>
        <IconButton
          size="small"
          style={{
            background: "#9A5AEF",
          }}
          onClick={handleSubmit}
          disabled={loading}
        >
          <ArrowUpward />
        </IconButton>
      </Toolbar>
      {loading && <LinearProgress />}
    </AppBar>
  );
}
