import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Skeleton,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { BarChart, LineChart } from "@mui/x-charts";
import { API_BASE, currentUser } from "../../App.js";
import { Autorenew, Repeat, RepeatOn } from "@mui/icons-material";

export default function SubscribersChangeContainer() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [windowDays, setWindowDays] = useState(30);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const endpoint = API_BASE + "/data/subscribers_change";
      const response = await axios.get(endpoint, {
        params: { artist_key: currentUser.key },
      });
      setData(response.data.results);
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      filterData(windowDays);
    }
  }, [data, windowDays]);

  const filterData = (window) => {
    const now = new Date();
    let filtered;
    if (window === 30) {
      const thirtyDaysAgo = new Date(now.setDate(now.getDate() - 30));
      filtered = data.filter((item) => new Date(item.date) >= thirtyDaysAgo);
    } else {
      filtered = data;
    }
    setFilteredData(filtered);
  };

  const handleWindowChange = (event, newWindow) => {
    if (newWindow !== null) {
      setWindowDays(newWindow);
    }
  };

  const mostRecentTotal =
    filteredData.length > 0 ? filteredData[filteredData.length - 1].total : 0;

  return (
    <Box id="main-content" sx={{ py: 4, background: "#1D1D1D" }}>
      <Box
        sx={{ my: 2, px: 2, display: "flex", justifyContent: "space-between" }}
      >
        {/* <Autorenew color="secondary" sx={{ mr: 1 }} /> */}
        <Typography variant="h6" color="secondary">
          Total Subscribers
        </Typography>

        <Typography variant="h6">{mostRecentTotal}</Typography>
      </Box>
      <Box
        sx={{
          px: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ToggleButtonGroup
          value={windowDays}
          exclusive
          onChange={handleWindowChange}
          aria-label="data window"
          size="small"
          sx={{ my: 1 }}
        >
          <ToggleButton
            value={30}
            aria-label="30 days"
            sx={{ borderRadius: 20 }}
          >
            30 Days
          </ToggleButton>
          <ToggleButton
            value="max"
            aria-label="max"
            sx={{ borderRadius: 20, px: 2 }}
          >
            Max
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {filteredData.length <= 0 && !loading && (
        <Typography variant="body1" sx={{ p: 2 }}>
          No data available
        </Typography>
      )}

      {filteredData.length > 0 && (
        <LineChart
          margin={{ top: 20, right: 20, bottom: 50 }}
          style={{ marginTop: "15px" }}
          height={240}
          dataset={filteredData}
          xAxis={[{ dataKey: "date", scaleType: "band" }]}
          series={[{ dataKey: "total" }]}
          slotProps={{
            legend: {
              position: "bottom",
            },
          }}
        />
      )}
      <Box sx={{ px: 2, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body1" color="darkgray">
          Daily +/-
        </Typography>
      </Box>

      {filteredData.length <= 0 && !loading && (
        <Typography variant="body1" sx={{ p: 2 }}>
          No data available
        </Typography>
      )}

      {filteredData.length > 0 && (
        <BarChart
          margin={{ top: 20, right: 20, bottom: 50 }}
          style={{ marginTop: "15px" }}
          height={240}
          dataset={filteredData}
          xAxis={[{ dataKey: "date", scaleType: "band" }]}
          series={[
            { dataKey: "subscribed" },
            { dataKey: "canceled", color: "#ffa726" },
          ]}
          slotProps={{
            legend: {
              position: "bottom",
            },
          }}
        />
      )}

      {loading && data.length <= 0 && (
        <Box>
          <Skeleton variant="rectangular" height={240} />
        </Box>
      )}
    </Box>
  );
}
