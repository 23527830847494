import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Card,
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
} from "@mui/material";

import { ArrowBackIos, Autorenew, Pause, Verified } from "@mui/icons-material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CancelIcon from "@mui/icons-material/Cancel";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ArtistsGallery from "../components/ArtistsGallery";
import CurrentUserDrawer from "../components/CurrentUserDrawer";

import AppIcon from "../assets/app-icon.svg";

import { API_BASE, artistKey, currentUser } from "../App";
import { fromNow, setArtistKey } from "../utils";
import NotebookIcon from "../fragments/NotebookIcon";

export default function Subscriptions() {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
    },
  });

  const [loading, setLoading] = useState(false);
  // const [subscriptions, setSubscriptions] = useState([]);
  const [active, setActive] = useState(null);
  const [expired, setExpired] = useState(null);
  const [managed, setManaged] = useState(null);

  const [discoverArtists, setDiscoverArtists] = useState([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoading(true);
      const endpoint = `${API_BASE}/subscriptions`;
      const response = await axios.get(endpoint, {
        params: {
          user_key: currentUser.key,
        },
      });

      const active = response.data.active;
      if (currentUser.role === "artist") {
        active.push({
          artist: currentUser,
          status: "active",
        });
      }
      setActive(active);
      setExpired(response.data.expired);

      setLoading(false);
    };
    fetchSubscriptions();
  }, []);

  useEffect(() => {
    const fetchDiscover = async () => {
      const endpoint = `${API_BASE}/discover`;
      const response = await axios.get(endpoint, {
        params: {
          user_key: currentUser.key,
        },
      });
      const sortedArtists = response.data.artists.sort((a, b) => a.id - b.id);
      setDiscoverArtists(sortedArtists);
    };
    fetchDiscover();
  }, []);

  const artistSubscription = {
    artist: currentUser,
    status: "active",
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background:
          "linear-gradient(180deg, rgba(18,18,18,0) 0%, rgba(36,36,36,1) 200%)",
      }}
    >
      <PageAppBar />
      {/* <MainAppBar /> */}

      {loading ? (
        <Loading loading={loading} />
      ) : (
        <Box sx={{ p: 2 }}>
          {/* {
            currentUser.role === "artist" && (
              <ArtistCard subscription={artistSubscription} />
            )
          } */}

          {active?.map((subscription, index) => (
            <Box key={index}>
              <ArtistCard subscription={subscription} />
            </Box>
          ))}

          {active?.length === 0 && (
            <Typography variant="body1" color="darkgray">
              No active subscriptions
            </Typography>
          )}

          {/* {managed?.length > 0 && (
            <Typography variant="h6" sx={{mt: 4}}>Managed Artists</Typography>
          )}
          {managed?.map((subscription, index) => (
            <Box key={index}>
              <ArtistCard subscription={subscription} />
            </Box>
          ))} */}

          {expired?.length > 0 && (
            <Typography variant="h6" sx={{ mt: 4 }}>
              Expired Subscriptions
            </Typography>
          )}

          {expired?.map((subscription, index) => (
            <Box key={index}>
              <ArtistCard subscription={subscription} />
            </Box>
          ))}

          {discoverArtists?.length > 0 && (
            <>
              <Typography variant="h6" sx={{ mt: 4 }}>
                Discover
              </Typography>
              <ArtistsGallery artists={discoverArtists} />
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

function ArtistCard({ subscription }) {
  const [open, setOpen] = useState(false);
  const isExpired = subscription.expires < Date.now() / 1000;

  return (
    <Card sx={{ my: 2, display: "flex", height: "60px" }}>
      <Box>
        <img
          src={subscription.artist.meta?.profile || AppIcon}
          onClick={() => setOpen(true)}
          style={{ width: "60px", height: "60px", objectFit: "cover" }}
        />
      </Box>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          px: 2,
        }}
      >
        <Box sx={{ py: 1 }}>
          <Typography variant="h7" sx={{ fontWeight: 600 }}>
            {subscription.artist?.name || subscription.artist.key}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", opacity: 0.7 }}>
            {!subscription.stripe_id && (
              <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                <Verified
                  sx={{ width: 12, height: 12, mr: 0.5 }}
                  color="secondary"
                />
                <Typography variant="body2">Artist Access</Typography>
              </Box>
            )}

            {/* {subscription.stripe_id && subscription.status == "active" && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Autorenew
                  sx={{ width: 12, height: 12, mr: 0.5 }}
                  color="secondary"
                />
                <Typography variant="body2">
                  Renews {fromNow(subscription.expires)}
                </Typography>
              </Box>
            )} */}

            {subscription.stripe_id && subscription.status !== "active" && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Pause sx={{ width: 12, height: 12, mr: 0.5 }} />
                <Typography variant="body2">
                  {isExpired ? "Expired" : "Accesss expires"}{" "}
                  {fromNow(subscription.expires)}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <IconButton onClick={() => setOpen(true)} sx={{ ml: 1 }}>
          <MoreHorizIcon color="info" />
        </IconButton>
      </Box>
      <ActionDrawer subscription={subscription} open={open} setOpen={setOpen} />
    </Card>
  );
}

function ActionDrawer({ subscription, open, setOpen }) {
  const gotoArtist = (artistKey) => {
    setArtistKey(artistKey);
    window.location.href = "/" + artistKey;
  };
  const navigate = useNavigate();

  const handleCancel = async () => {
    const result = confirm("Are you sure you want to cancel?");
    if (result) {
      const endpoint = `${API_BASE}/subscriptions/cancel`;
      await axios.post(endpoint, {
        user_key: subscription.user_key,
        subscription_id: subscription.id, // Ensure you use the correct ID
      });
      setOpen(false);
      window.location.reload();
    }
  };

  const handleSubscribe = async () => {
    navigate("/" + subscription.artist.key);
  };

  return (
    <Box>
      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        <Container maxWidth="sm">
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={() => setOpen(false)} sx={{ m: 1 }}>
              <CancelIcon />
            </IconButton>
          </Box>
          <List sx={{ mb: 4 }}>
            <ListItem
              button
              key="open-artist"
              onClick={() => gotoArtist(subscription.artist.key)}
              sx={{ my: 2 }}
            >
              <AutoStoriesIcon sx={{ mr: 2 }} color="info" />
              <Typography>Open {subscription.artist.name} Notebook</Typography>
            </ListItem>
            {subscription.stripe_id && subscription.status === "active" && (
              <>
                <ListItem
                  button
                  key="cancel"
                  onClick={handleCancel}
                  sx={{ mb: 2 }}
                >
                  <DoNotDisturbIcon sx={{ mr: 2 }} color="info" />
                  <Typography>Cancel Subscription</Typography>
                </ListItem>
              </>
            )}
            {subscription.status != "active" && (
              <ListItem
                button
                key="cancel"
                onClick={handleSubscribe}
                sx={{ mb: 2 }}
              >
                <AddCircleOutlineIcon sx={{ mr: 2 }} color="info" />
                <Typography>Activate Subscription</Typography>
              </ListItem>
            )}
          </List>
        </Container>
      </Drawer>
    </Box>
  );
}

function Loading({ loading }) {
  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

function PageAppBar() {
  const navigate = useNavigate();

  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "40px" }}>
          {artistKey && (
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIos />
            </IconButton>
          )}
        </Box>

        <Box style={{ display: "flex", alignItems: "center" }}>
          <NotebookIcon />
          <Typography variant="h6" sx={{ ml: 1 }}>
            Notebook Library
          </Typography>
        </Box>

        <CurrentUserDrawer />
      </Toolbar>
    </AppBar>
  );
}
