import React, { useState } from "react";
import axios from "axios";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ActiveContentDrawer from "../../components/ActiveContentDrawer";

import { fromNow } from "../../utils";
import {
  ChatBubbleOutline,
  Star,
  StarBorder,
  Verified,
} from "@mui/icons-material";

import { API_BASE, currentUser } from "../../App";
import { Divider } from "@mui/material";

export default function FeedCard({ content, index }) {
  const [open, setOpen] = useState(false);
  const [favorite, setFavorite] = useState(content.user_favorite);
  const [favoriteCount, setFavoriteCount] = useState(content.favorite_count);

  const handleFavorite = async () => {
    setFavorite(!favorite);
    setFavoriteCount(favorite ? favoriteCount - 1 : favoriteCount + 1);
    const endpoint = API_BASE + "/event";
    const response = await axios.post(endpoint, {
      user_key: currentUser.key,
      content_key: content.key,
      type: favorite ? "unfavorite" : "favorite",
    });
  };

  return (
    <Box id={content.key} key={index} sx={{ my: 2 }}>
      <Box style={{ borderRadius: "20px" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          sx={{ pt: 2, pb: 0, px: 3 }}
          onClick={() => setOpen(true)}
        >
          <Avatar
            src={content.owner.meta.profile}
            sx={{ width: 20, height: 20, marginRight: 1 }}
          />
          <Typography
            variant="caption"
            style={{ marginLeft: "5px", color: "darkgray" }}
          >
            {content.owner.name}
          </Typography>
          {content.owner.role == "user" && content.owner.verified && (
            <Verified
              color="primary"
              style={{ fontSize: "16px", marginLeft: "5px" }}
            />
          )}
          <Typography
            variant="caption"
            style={{ marginLeft: "5px", color: "darkgray" }}
          >
            • {fromNow(content.created)}
          </Typography>
        </Box>
        <Box sx={{ pt: 1, pb: 0, px: 3 }} onClick={() => setOpen(true)}>
          <Typography sx={{ fontWeight: 800, fontSize: "1.2rem" }}>
            {content.meta.title}
          </Typography>
          <Box sx={{ my: 1 }}>
            <Typography variant="comment" style={{ color: "lightgray" }}>
              {/* {content.meta.text} slice to 80 chars if over 80 chars*/}
              {content.meta.text.length > 80
                ? content.meta.text.slice(0, 80) + "..."
                : content.meta.text}
            </Typography>
          </Box>
        </Box>
        <CardActions style={{ paddingTop: "5px" }}>
          <Box
            id="post-actions"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              px: 2,
              my: 1,
            }}
          >
            <Box id="handle-favorite" sx={{ ml: -1, mr: 1 }}>
              <IconButton onClick={handleFavorite}>
                {favorite ? <Star color="secondary" /> : <StarBorder />}
              </IconButton>
              <Typography variant="caption">
                {favoriteCount > 0 && favoriteCount}
              </Typography>
            </Box>
            <Box id="handle-chat" sx={{ mr: 2 }}>
              <IconButton
                onClick={() => setOpen(true)}
                style={{ marginLeft: "5px" }}
              >
                <ChatBubbleOutline />
              </IconButton>
              <Typography variant="caption" style={{ marginRight: "5px" }}>
                {content.comment_count > 0 && content.comment_count}
              </Typography>
            </Box>
            <Typography variant="caption" style={{ color: "darkgray" }}>
              {fromNow(content.updated)}
            </Typography>
          </Box>
        </CardActions>
      </Box>
      {/* <Divider /> */}

      <ActiveContentDrawer content={content} open={open} setOpen={setOpen} />
    </Box>
  );
}
