import React from "react";
import { Avatar, Box, TextField } from "@mui/material";
import { currentUser } from "../../App";

export default function ContentTextField({
  text,
  setText,
  placeholder,
  helperText,
}) {
  const handleTextChange = (e) => {
    const newValue = e.target.value;
    setText(newValue);
  };

  return (
    <Box style={{ display: "flex", alignItems: "flex-start" }}>
      <Avatar
        src={currentUser.meta.profile}
        sx={{ width: 48, height: 48, mr: 1 }}
        style={{
          border: 1,
          borderColor: "#04FFFF",
          borderStyle: "solid",
          marginTop: "4px",
        }}
      />
      <TextField
        fullWidth
        multiline
        sx={{ mt: 0 }}
        autoComplete="off"
        placeholder={placeholder || "Include a message..."}
        helperText={helperText}
        value={text}
        onChange={handleTextChange}
        margin="normal"
        InputProps={{
          style: {
            borderRadius: "30px",
          },
        }}
      />
    </Box>
  );
}
